<template>
  <referentiel-list
    :listTitle="'Liste de fonction d\'organismes'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nom"
                @input="handleInput"
                type="text"
                class="form-control"
                id="nom"
                name="nom"
                placeholder="Intitulé"
              />
              <label for="nom">Nom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="" v-slot="{}">
            <div class="d-flex justify-content-between mb-2">
              <div
                v-for="option of optionFonctions"
                :key="option.code"
                class="field-radiobutton d-flex"
              >
                <PRadioButton
                  :id="option.code"
                  name="option"
                  :value="option.code"
                  v-model="editableItem.optionFonction"
                />
                <label :for="option.code">{{ option.name }}</label>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12" v-if="editableItem.optionFonction == 'NORMAL'">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select
                id="organisme"
                name="organisme"
                option-label="intitule"
                option-value="id"
                v-model="editableItem.organisme"
                :options="organismes"
                class="form-select"
              >
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
              <label for="organisme">Organisme</label>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select
                id="fonction"
                name="fonction"
                option-label="nom"
                option-value="id"
                v-model="editableItem.fonction"
                :options="fonctions"
                class="form-select"
              >
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
              <label for="fonction">Fonction</label>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
export default {
  components: {
    ReferentielList,
  },
  data() {
    return {
      optionFonction: null,
      optionFonctions: [
        { code: "NORMAL", name: "NORMAL" },
        { code: "NIVEAU_ANTENNE", name: "NIVEAU ANTENNE" },
        { code: "NIVEAU_COMMUNE", name: "NIVEAU COMMUNE" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      organismes: "organisme/organismes",
      fonctions: "fonction/fonctions",
    }),
    referentielName() {
      return "organismeFonction";
    },
    emptyReferentielItem() {
      return {
        nom: null,
        fonction: null,
        organisme: null,
        optionFonction: null,
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "fonction.nom", label: "Description" },
        "actions",
      ];
    },
  },
  created() {
    this.fetchOrganismes();
    this.fetchFonctions();
  },
  methods: {
    ...mapActions({
      fetchOrganismes: "organisme/fetchOrganismes",
      fetchFonctions: "fonction/fetchFonctions",
    }),
  },
};
</script>

<style></style>
